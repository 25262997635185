import { call, put, takeLatest } from 'redux-saga/effects';
import CountiesActions, { CountiesTypes } from './Actions';
import axios from '../../utils/axios';


export function* getCounties() {
	yield put(CountiesActions.countiesLoading());
	try {
		const response = yield call(() => axios.get('/nameGeneration'));
		yield put(CountiesActions.countiesSuccess(response.data));
	} catch (error) {
		console.log(error);
		yield put(CountiesActions.countiesFail(error));
	}
}

export default function* countiesSaga() {
	yield takeLatest(CountiesTypes.COUNTIES, getCounties);
}
