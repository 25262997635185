import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	counties: null,
	countiesLoading: null,
	countiesSuccess: ['counties'],
	countiesFail: ['errors']
});

export const CountiesTypes = Types;
export default Creators;
