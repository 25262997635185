import React from "react";
import styles from "./Header.module.scss";

import logo from "../../assets/images/logo.png";
import {Fade} from "react-reveal";

class Header extends React.Component{
    state = {};

    render() {
        return(
            <div className={styles.header}>
                <div className={styles.container}>
                    <Fade top>
                        <img src={logo} className={styles.logo} alt="Noul Nissan Juke - The Coupe Crossover"/>

                        <h1>
                            <span>Noul</span>
                            Nissan Juke
                            <small>The Coupe Crossover</small>
                        </h1>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default Header;
