import React from 'react';
import { Router as BrowserRouter, Switch, Route } from 'react-router-dom';

import { history } from '../../stores/createStore';
import config from '../../config';

import Homepage from '../../containers/Homepage';
import Success from "../../containers/Success";

const Router = () => (
    <BrowserRouter history={history}>
        <Switch>
            <Route exact path={config.path.index} component={Homepage} />
            <Route exact path={config.path.success} component={Success} />
        </Switch>
    </BrowserRouter>
);

export default Router;