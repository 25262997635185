import React from "react";
import styles from "./Footer.module.scss";

import image from "../../assets/images/new-juke-back.png";

class Footer extends React.Component{
    render() {
        return(
            <div className={styles.footer}>
                <div className={styles.container}>
                    <h2><b>O experiență <span>all-inclusive</span></b> de o săptămână în România</h2>
                    <img src={image} alt="Noul Nissan Juke"/>

                    <div className={styles.campaignDetails}>
                        <h3><b>500 de euro extra</b> discount <span>la achiziționare</span> <span className={styles.greyedOut}>premiu garantat</span></h3>
                        <p>Poți câștiga garantat un voucher de 500 de euro discount la achiziție, plus alte premii prin tragere la sorți: o experiență all inclusive de o săptămână sau 3 experiențe de test drive de weekend la volanul Noului Juke.</p>
                    </div>
                </div>

                <div className={styles.bottomFooter}>
                    <div className={styles.container}>
                        <h3>Înscrie-te și vino la test drive!</h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
