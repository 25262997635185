import React from "react";

// Components
import Form from "../../components/Form/Form";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";

class Homepage extends React.Component{
	state = {};

	render() {
		return(
			<>
				<Header />
				<Form />
				<Footer />
			</>
		)
	}
}

export default Homepage;
