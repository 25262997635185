import { call, put, takeLatest } from 'redux-saga/effects';
import LeadGenerationActions, { LeadGenerationTypes } from './Actions';
import axios from '../../utils/axios';
import config from '../../config';
import { push } from 'connected-react-router';

export function* leadGeneration({params}) {
    yield put(LeadGenerationActions.leadGenerationLoading());
    try {
        const response = yield call(() => axios.post('/nameGeneration', params));
        yield put(LeadGenerationActions.leadGenerationSuccess(response.data));
        yield put(push(config.path.success));
    } catch (error) {
        yield put(LeadGenerationActions.leadGenerationFail(error.response.data.errors));
    }
}

export default function* leadGenerationSaga() {
    yield takeLatest(LeadGenerationTypes.LEAD_GENERATION, leadGeneration);
}
