import React from 'react';
import Fade from 'react-reveal/Fade';
import styles from './Success.module.scss';
import Header from '../../components/Header';

class Success extends React.Component{
    state = {};
    render() {
        return (
            <>
                <Header />

                <div className={styles.formWrapper}>
                    <Fade right>
                        <div className={styles.carImage}/>
                    </Fade>

                    <div className={styles.container}>
                        <Fade bottom>
                            <div className={styles.formDetails}>
                                <h2>Felicitări!</h2>
                                <p>Ai completat cu succes formularul de înscriere la test drive cu <b>NOUL NISSAN JUKE</b>.</p>
                                <p>În curând, vei fi contactat telefonic de către un reprezentant al echipei Vocea Clientului Nissan pentru a programa sesiunea de test drive solicitată.</p>

                                <a href="https://www.nissan.ro/gama/configurator.html">Configurează un model Nissan</a>
                                <a href="#">Descarcă o broșură</a>
                            </div>
                        </Fade>
                    </div>
                </div>
            </>
        );
    }
}

export default Success;