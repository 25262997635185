import './styles/App.scss';
import React, { Component } from 'react';
import Router from './components/Routes/router';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './stores/createStore';
import { Provider } from 'react-redux';
import createStore from './stores';

const store = createStore(history);

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <div className="App">
                        <Router />
                    </div>
                </ConnectedRouter>
            </Provider>
        );
    }
}
