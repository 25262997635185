import { createReducer } from 'reduxsauce';
import { LeadGenerationTypes } from './Actions';

const INITIAL_STATE = {
    leadGeneration: null,
    loading: false,
    errors: null
};

export const leadGenerationLoading = (state) => ({
    ...state,
    loading: true,
    errors: null
});

export const leadGenerationSuccess = (state) => ({
    ...state,
    loading: false,
    errors: null
});

export const leadGenerationFail = (state, { errors }) => ({
    ...state,
    loading: false,
    errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
    [LeadGenerationTypes.LEAD_GENERATION_LOADING]: leadGenerationLoading,
    [LeadGenerationTypes.LEAD_GENERATION_SUCCESS]: leadGenerationSuccess,
    [LeadGenerationTypes.LEAD_GENERATION_FAIL]: leadGenerationFail
});
